<template lang="pug">
.edit-title
  v-editable(
    ref="editTitle",
    hide-icon,
    toggleByIconOnly,
    :error-text="'name' | errorText",
    @change="$emit('setTitle', $event)"
  )
    h2.title {{ title }}
  v-btn(icon, @click="$refs.editTitle.toggleEdit()")
    icon-pen
</template>

<script>
import IconPen from '@/components/svg/icons/IconPen.vue'
export default {
  name: 'editTitle',
  components: {
    IconPen
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
$size-pen: 24px;
.edit-title {
  display: flex;
  align-items: center;
  gap: 8px;

  .v-btn {
    width: $size-pen;
    height: $size-pen;
    color: $body-text-2;
    &:active {
      color: $color-white;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

  ::v-deep .v-editable__input {
    font-size: 20px!important;
    line-height: 24px!important;
    font-weight: 400!important;
    height: fit-content !important;
    box-shadow: none;
    background: none;
  }
}
</style>

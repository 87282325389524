<template functional>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" :class="[data.staticClass]">
  <path d="M1.87559 10.311L1.03114 13.9555C1.00201 14.0887 1.00301 14.2268 1.03406 14.3596C1.06512 14.4923 1.12545 14.6165 1.21064 14.723C1.29583 14.8295 1.40373 14.9156 1.52646 14.975C1.6492 15.0345 1.78366 15.0658 1.92003 15.0666C1.98357 15.073 2.0476 15.073 2.11114 15.0666L5.77781 14.2222L12.8178 7.20882L8.88892 3.28882L1.87559 10.311Z" fill="currentColor"/>
  <path d="M15.0311 3.69766L12.4089 1.07543C12.2365 0.903907 12.0032 0.807617 11.76 0.807617C11.5168 0.807617 11.2835 0.903907 11.1111 1.07543L9.65332 2.53321L13.5778 6.45766L15.0355 4.99988C15.1209 4.91414 15.1884 4.81241 15.2344 4.70053C15.2803 4.58865 15.3038 4.4688 15.3034 4.34785C15.3029 4.22689 15.2787 4.10721 15.232 3.99564C15.1853 3.88407 15.117 3.78281 15.0311 3.69766Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'IconPen'
}
</script>

<style>
</style>
